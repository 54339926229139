<template>
  <div class="medicalDetails">
    <img src="@/assets/img/goback_icon.svg" class="gobackBtn" @click="goBack">
    <h1 class="title">{{details.title}}</h1>
    <p class="date">{{details.creatDate}}</p>
    <div class="content" v-html="details.content"></div>
    <div class="collect" @click="collect">
      <img :src="require(`@/assets/img/collection${details.isCollection ? '_active' : ''}.svg`)" alt="">
      <span>收藏</span>
    </div>
  </div>
</template>
<script>

import { articleDetail, articlesCollect } from '@/api/getData'
export default {
  name: "MedicalDetails",
  data() {
    return {
      details: {}
    }
  },
  methods: {

    //收藏或取消收藏
    collect(resourceId, isisCollection) {
      if (this.$store.state.vToken) {
        articlesCollect(this.$route.query.articleId).then(() => {
          this.$message.success('操作成功')
          this.getArticledetail()
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.$router.push('/login-pwd')
      }
    },
    goBack() {
      window.history.go(-1);
    },
    getArticledetail() {
      articleDetail(this.$route.query.articleId).then(res => {
        this.details = res.result
      }).catch(error => {
        console.log(error)
      })
    }
  },
  mounted() {
    this.getArticledetail()
  }
}
</script>

<style lang="scss" scoped>
.medicalDetails {
  padding: 0 0.16rem;
  background: #fff;
  overflow: hidden;
  .gobackBtn {
    margin: 0.16rem 0;
    display: block;
    width: 0.31rem;
    height: 0.31rem;
  }
  .title {
    font-size: 0.18rem;
	line-height: 0.3rem;
  }
  .date {
    font-size: 0.1rem;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 0.08rem;
  }
  :deep(.content) {
    padding-bottom: 0.5rem;
    p {
      margin-top: 0.16rem;
      font-size: 0.14rem;
      line-height: 0.24rem;
    }
    img {
      display: block;
      border-radius: 0.04rem;
      width: 100%;
    }
    h2{
      margin-top: 0.16rem;
    }
  }
  .collect {
    position: fixed;
    right: 0.1rem;
    bottom: 0.7rem;
    width: 0.96rem;
    height: 0.48rem;
    border-radius: 0.3rem;
    background: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(36, 52, 48, 0.3);
    line-height: 0.48rem;
    text-align: center;
    img,
    span {
      vertical-align: middle;
    }
	img{
		display: inline-block;
		width: 0.2rem;
		margin-right: 0.05rem;
	}
  }
}
</style>